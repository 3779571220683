<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> icon设置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="handle-del mr10"
          @click="addmenu"
          >添加icon</el-button
        >
        <!-- <el-input v-model="query.name" placeholder="用户名" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button> -->
      </div>
      <el-table :data="tableData" border  
                class="table" ref="multipleTable" 
                row-key="id"
                default-expand-all
                :tree-props="{child_menu: 'child_menu', parent_id: 'parent_id'}"

                header-cell-class-name="table-header" 
                @selection-change="handleSelectionChange">
        <!-- <template #default="scope">￥{{ scope.row.name }}</template> -->
        <el-table-column prop="list_order" label="排序" width="66" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="66" align="center"></el-table-column>
        <el-table-column label="菜单名称">
          <template #default="{ row }">
            <span>{{
              row.title == ''
                ? "-"
                : row.title
            }}</span>
          </template>
          
        </el-table-column>
        <el-table-column label="路由">
          <template #default="{ row }">
            <span>{{
              row.path == ''
                ? "-"
                : row.path
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="菜单icon">
          <template #default="{ row }">
            <span>{{
              row.icon == ''
                ? "-"
                : row.icon
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="88">
          <template #default="{ row }">
            <span>{{
              row.status == 0
                ? "隐藏"
                : row.status == 1
                ? "显示"
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template #default="scope">
            <el-button type="text" icon="el-icon-edit-outline" @click="addsmall(scope.$index, scope.row)" >添加子菜单</el-button>
            <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)" >编辑</el-button>
            <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)" >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex" :page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 添加弹出框 -->
    <el-dialog title="添加菜单" v-model="addVisible" width="40%">
      <el-form :model="addform" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="上级">
          <el-select v-model="addform.parent_id" placeholder="作为一级菜单">
              <el-option
                v-for="item in getmenulistArr"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
          </el-select>
          <p class="text_01">注：此选项  "未选" 作为一级菜单，选择后作为子菜单添加</p>
        </el-form-item>
        <el-form-item label="菜单名称" prop="title">
          <el-input v-model="addform.title" placeholder="例：***管理"></el-input>
        </el-form-item>
        <el-form-item label="路由路径" prop="path">
          <el-input v-model="addform.path" placeholder="例：login"></el-input>
        </el-form-item>
        <el-form-item label="导航名称" prop="name">
          <el-input v-model="addform.name" placeholder="例：/ + 路由路径"></el-input>
          <p class="text_01">例： / + 路由路径</p>
        </el-form-item>
        <el-form-item label="文件路径" prop="component">
          <el-input v-model="addform.component" placeholder="例： views/index/index"></el-input>
        </el-form-item>

        <el-form-item label="导航icon" prop="icon">
          <el-input v-model="addform.icon" placeholder="请输入导航icon"></el-input>
          <p class="text_01">请前往<a href="https://element.eleme.cn/#/zh-CN/component/icon" target="_blank" >https://element.eleme.cn/#/zh-CN/component/icon</a>选择icon图标
          </p>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="addform.status">
            <el-radio :label="0">隐藏</el-radio>
            <el-radio :label="1">显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="list_order">
          <el-input v-model="addform.list_order" placeholder="请输入排序，此排序展示于导航栏位置"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="addmenuFun('addform')">确定</el-button>
        </span>
      </template>
    </el-dialog>
  <!-- 添加子菜单弹出框 -->
  <el-dialog title="添加子菜单" v-model="submenuVisible" width="40%">
      <el-form :model="submenuform" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="上级" prop="parent_id">
          <el-select v-model="submenuform.parent_id" placeholder="当前菜单">
              <el-option
                v-for="item in getmenulistArr"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称" prop="title">
          <el-input v-model="submenuform.title" placeholder="例：***管理"></el-input>
        </el-form-item>
        <el-form-item label="路由路径" prop="path">
          <el-input v-model="submenuform.path" placeholder="例：login"></el-input>
        </el-form-item>
        <el-form-item label="导航名称" prop="name">
          <el-input v-model="submenuform.name" placeholder="例：/ + 路由路径"></el-input>
          <p class="text_01">例： / + 路由路径</p>
        </el-form-item>
        <el-form-item label="文件路径" prop="component">
          <el-input v-model="submenuform.component" placeholder="例： views/index/index"></el-input>
        </el-form-item>

        <el-form-item label="导航icon" prop="icon">
          <el-input v-model="submenuform.icon" placeholder="请输入导航icon"></el-input>
          <p class="text_01">请前往<a href="https://element.eleme.cn/#/zh-CN/component/icon" target="_blank" >https://element.eleme.cn/#/zh-CN/component/icon</a>选择icon图标
          </p>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="submenuform.status">
            <el-radio :label="0">隐藏</el-radio>
            <el-radio :label="1">显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="list_order">
          <el-input v-model="submenuform.list_order" placeholder="请输入排序，此排序展示于导航栏位置"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="submenuVisible = false">取 消</el-button>
          <el-button type="primary" @click="submenuFun('submenuform')">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="40%">
      <el-form :model="editform" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="上级">
          <el-select v-model="editform.parent_id" placeholder="请选择上级菜单">
              <el-option
                v-for="item in getmenulistArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称" prop="title">
          <el-input v-model="editform.title"></el-input>
        </el-form-item>
        <el-form-item label="路由路径" prop="path">
          <el-input v-model="editform.path"></el-input>
        </el-form-item>
        <el-form-item label="导航名称" prop="name">
          <el-input v-model="editform.name"></el-input>
          <p class="text_01">例： / + 路由路径</p>
        </el-form-item>
        <el-form-item label="文件路径" prop="component">
          <el-input v-model="editform.component"></el-input>
        </el-form-item>

        <el-form-item label="导航icon" prop="icon">
          <el-input v-model="editform.icon"></el-input>
          <p class="text_01">请前往<a href="https://element.eleme.cn/#/zh-CN/component/icon" target="_blank" >https://element.eleme.cn/#/zh-CN/component/icon</a>选择icon图标
          </p>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="editform.status">
            <el-radio :label="0">隐藏</el-radio>
            <el-radio :label="1">显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="list_order">
          <el-input v-model="editform.list_order"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {add_post, menu_list,delete_list,edit_list,first_menulist} from "../../api/index";
export default {
  name: "basetable",
  data() {
    return {
      //添加菜单数据
      addform: {
        name: this.name,
        title: this.title,
        path: this.path,
        icon: this.icon,
        status: this.status || 1,
        component: this.component,
        list_order: this.list_order,
        parent_id: this.parent_id,
      },
      // 子菜单
      submenuform: {
        name: this.name,
        title: this.title,
        path: this.path,
        icon: this.icon,
        status: this.status || 1,
        component: this.component,
        list_order: this.list_order,
        parent_id: this.parent_id,
      },
      // 编辑菜单
      editform:{
        editId:this.editId,
        name: this.name,
        title: this.title,
        path: this.path,
        icon: this.icon,
        status: this.status,
        component: this.component,
        list_order: this.list_order,
        parent_id: this.parent_id,
      },
      getmenulistArr:[],
      editId:'',
      query: {
        address: "",
        name: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入标签名称、例： / + 路由路径",
            trigger: "blur",
          },
        ],
        component: [
          {
            required: true,
            message: "请输入文件路径、例：views/index/index",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入导航名称、例：***管理",
            trigger: "blur",
          },
        ],
        path: [
          {
            required: true,
            message: "请输入路由路径、例：login",
            trigger: "blur",
          },
        ],
        parent_id: [
          { required: true, message: "请选择上级菜单", trigger: "change" },
        ],
        icon: [
          {
            required: true,
            message: "请前往icon地址选择菜单icon、例：el-icon-phone-outline",
            trigger: "blur",
          },
        ],
        status: [
          { required: true, message: "请选择菜单状态", trigger: "change" },
        ],
        list_order: [
          {
            required: true,
            message: "请输入排序，此排序展示于导航栏位置",
            trigger: "blur",
          },
        ],
      },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      submenuVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getData();
  },
  load(tree, treeNode, resolve) {
        setTimeout(() => {
          resolve([
            {
              id: 31,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
            }, {
              id: 32,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
            }
          ])
        }, 1000)
      },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      menu_list().then((res) => {
        this.tableData = res.data.menu;
        this.pageTotal = res.data.count;
      });
    },
    getmenulist(){
      first_menulist().then((res) =>{
        console.log(res);
        this.getmenulistArr = res.data
      })
    },
    // 删除操作
    handleDelete(index,row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(() => {
        console.log(row);
        var data = {
            id:row.id
        }
         delete_list(data).then((res) => {
             if(res.code == 1){
                console.log(res);
                this.$message.success("删除成功");
                this.tableData.splice(index, 1);
                this.getData();
             }else{
                this.$message.error(res.msg);
             }
            });
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 添加菜单
    addmenu() {
      this.addVisible = true;
      this.getmenulist()
    },
    // 添加子菜单
    submenuFun(){
      
          this.submenuVisible = false;
          add_post(this.submenuform).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getData()
            } else {
              this.$message.error(res.error);
            }
          });
      // this.$refs[submenuform].validate((valid) => {
      //   if (valid) {
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    // 添加保存
    addmenuFun(addform) {
      console.log(this.addform);
      this.$refs[addform].validate((valid) => {
        if (valid) {
          add_post(this.addform).then((res) => {
            if (res.code == 1) {
              this.addVisible = false;
              this.$message.success(res.msg);
              this.getData()
            } else {
              this.$message.error(res.error);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.editform = row;
      this.editform.editId = row.id;
      this.editVisible = true;
    },
    addsmall(){
      
      this.getmenulist();
      this.submenuVisible = true
        // this.$message.success( `添加子菜单`);
    },
    // 保存编辑
    saveEdit() {
      console.log(this.editform);
      edit_list(this.editform).then((res) => {
        if(res.code == 1){
            this.$message.success(res.msg);
            this.editVisible = false;
            this.getData()
        }else{
            this.$message.error(res.msg);

        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.getData();
    },
  },
};
</script>

<style scoped>
.text_01 {
  font-size: 13px;
  color: red;
}
.text_01 a {
  color: royalblue;
  text-decoration: underline;
  margin-left: 3px;
  margin-right: 3px;
}
.title_01 {
  margin-bottom: 20px;
}
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
